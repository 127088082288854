/***
 *     ######   ######## ##    ## ######## ########  ####  ######
 *    ##    ##  ##       ###   ## ##       ##     ##  ##  ##    ##
 *    ##        ##       ####  ## ##       ##     ##  ##  ##
 *    ##   #### ######   ## ## ## ######   ########   ##  ##
 *    ##    ##  ##       ##  #### ##       ##   ##    ##  ##
 *    ##    ##  ##       ##   ### ##       ##    ##   ##  ##    ##
 *     ######   ######## ##    ## ######## ##     ## ####  ######
 */
p, li { font-size: rem(18); font-family: $font-text; line-height: normal; }
button { font-family: $font_text; }

// Liens et boutons
%default-focus {
  &:focus { outline: 2px solid $color-focus; }
  &:focus:not(:focus-visible) { outline: none !important; }
}
a, button {
  @extend %default-focus; color: $color-black;
  &:hover { text-decoration: underline; }
}

// ==================================================================================================
// MQ: Téléphone seulement
// ==================================================================================================
@media #{$mq_phone_only} {
  p, li { font-size: rem(16); }
}


/***
 *    ##     ## ########    ###    ########  #### ##    ##  ######    ######
 *    ##     ## ##         ## ##   ##     ##  ##  ###   ## ##    ##  ##    ##
 *    ##     ## ##        ##   ##  ##     ##  ##  ####  ## ##        ##
 *    ######### ######   ##     ## ##     ##  ##  ## ## ## ##   ####  ######
 *    ##     ## ##       ######### ##     ##  ##  ##  #### ##    ##        ##
 *    ##     ## ##       ##     ## ##     ##  ##  ##   ### ##    ##  ##    ##
 *    ##     ## ######## ##     ## ########  #### ##    ##  ######    ######
 */
h1 { font-size: rem(30); font-family: $font-title; line-height: 1em; letter-spacing: 0.15em; text-transform: uppercase; color: $color-blue-dark; }

// ==================================================================================================
// MQ: Tablette et plus bas
// ==================================================================================================
@media #{$mq_tablet_and_down} {
  h1 { font-size: rem(24); }
}

// ==================================================================================================
// MQ: Téléphone seulement
// ==================================================================================================
@media #{$mq_phone_only} {
  h1 { font-size: rem(14); }
}


/***
 *    ########  ##     ## ######## ########  #######  ##    ##  ######
 *    ##     ## ##     ##    ##       ##    ##     ## ###   ## ##    ##
 *    ##     ## ##     ##    ##       ##    ##     ## ####  ## ##
 *    ########  ##     ##    ##       ##    ##     ## ## ## ##  ######
 *    ##     ## ##     ##    ##       ##    ##     ## ##  ####       ##
 *    ##     ## ##     ##    ##       ##    ##     ## ##   ### ##    ##
 *    ########   #######     ##       ##     #######  ##    ##  ######
 */
.c-cta {
  display: inline-block; font-size: rem(18); font-family: $font-text-alt; letter-spacing: 0.02em; text-decoration: underline; transition: color 0.3s $ease-out-quart;
  &:hover { color: $color-accent; }
}
