/***
 *    ##      ## ########     ###    ########  ########  ######## ########   ######
 *    ##  ##  ## ##     ##   ## ##   ##     ## ##     ## ##       ##     ## ##    ##
 *    ##  ##  ## ##     ##  ##   ##  ##     ## ##     ## ##       ##     ## ##
 *    ##  ##  ## ########  ##     ## ########  ########  ######   ########   ######
 *    ##  ##  ## ##   ##   ######### ##        ##        ##       ##   ##         ##
 *    ##  ##  ## ##    ##  ##     ## ##        ##        ##       ##    ##  ##    ##
 *     ###  ###  ##     ## ##     ## ##        ##        ######## ##     ##  ######
 */
.o-wrapper {
  width: calc(get-grid-cols(12)); max-width: 100%; margin-left: auto; margin-right: auto;
  @media #{$mq_tablet_only} { width: calc(get-grid-cols(8)); }
  @media #{$mq_phone_only} { width: calc(get-grid-cols(4)); }
}


/***
 *     ######   #######  ##        #######  ##    ## ##    ## ########  ######
 *    ##    ## ##     ## ##       ##     ## ###   ## ###   ## ##       ##    ##
 *    ##       ##     ## ##       ##     ## ####  ## ####  ## ##       ##
 *    ##       ##     ## ##       ##     ## ## ## ## ## ## ## ######    ######
 *    ##       ##     ## ##       ##     ## ##  #### ##  #### ##             ##
 *    ##    ## ##     ## ##       ##     ## ##   ### ##   ### ##       ##    ##
 *     ######   #######  ########  #######  ##    ## ##    ## ########  ######
 */
.o-cols {
  display: flex; justify-content: space-between; flex-wrap: wrap; width: 100%; margin-bottom: calc(var(--grid-gutter) * -1);
  > * { width: 100%; margin-bottom: var(--grid-gutter); }
}

@media #{$mq_desktop_only} {
  @include ff-cols-generator(o-cols, desktop);
}

@media #{$mq_tablet_only} {
  @include ff-cols-generator(o-cols, tablet);
}

@media #{$mq_phone_only} {
  @include ff-cols-generator(o-cols, phone);
}
