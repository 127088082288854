@import "variables";
@import "tools";
@import "reset";
@import "general";
@import "objects";


/***
 *     ######   ######## ##    ## ######## ########     ###    ##
 *    ##    ##  ##       ###   ## ##       ##     ##   ## ##   ##
 *    ##        ##       ####  ## ##       ##     ##  ##   ##  ##
 *    ##   #### ######   ## ## ## ######   ########  ##     ## ##
 *    ##    ##  ##       ##  #### ##       ##   ##   ######### ##
 *    ##    ##  ##       ##   ### ##       ##    ##  ##     ## ##
 *     ######   ######## ##    ## ######## ##     ## ##     ## ########
 */
:root {
  --base-px: 18;
  @media #{$mq-tablet-and-down} { --base-px: 16; } // Tablet and down
  @media #{$mq-phone-only} { --base-px: 14; } // Phone only

  --grid-cols: 12;
  --grid-gutter: 1.1rem;
  --grid-margin: 3.5rem;
  @media #{$mq-tablet-and-down} { --grid-cols: 8; --grid-margin: 2.25rem; }
  @media #{$mq-phone-only} { --grid-cols: 4; --grid-margin: 1.75rem; }
}

html, body { min-height: 100%; }
html { color: $color-black; font-family: $font-text; background-color: $color-white; }
body {
  -webkit-text-size-adjust: 100%; display: flex; flex-direction: column; min-height: 100vh; opacity: 0; transition: opacity 0.5s $ease-in-quad;
  html.page-loaded & { opacity: 1; }
}
main { display: flex; flex: 1; background-color: $color-off-black; overflow: hidden; }
::selection { color: $color-white; background-color: $color-accent; }


/***
 *    ##     ##    ###    #### ##    ##
 *    ###   ###   ## ##    ##  ###   ##
 *    #### ####  ##   ##   ##  ####  ##
 *    ## ### ## ##     ##  ##  ## ## ##
 *    ##     ## #########  ##  ##  ####
 *    ##     ## ##     ##  ##  ##   ###
 *    ##     ## ##     ## #### ##    ##
 */
/***
 *     #####   ####
 *     #    # #    #
 *     #####  #
 *     #    # #  ###
 *     #####   ####
 */
.c-bg {
  width: 100%; height: 100%; position: absolute; top: 0; left: 0; pointer-events: none;
  &__video { width: 100%; height: 100%; object-fit: cover; }

  // ================================================================================================================
  // Media Queries
  // ================================================================================================================
  // Tablet and down ------------------------------------------------------------------------------------------------
  @media #{$mq-tablet-and-down} {
    max-height: 55rem;
  }

  // Phone only -----------------------------------------------------------------------------------------------------
  @media #{$mq-phone-only} {
    max-height: 40rem;
  }
}


/***
 *      ####   ####  #    # ##### ###### #    # #####
 *     #    # #    # ##   #   #   #      ##   #   #
 *     #      #    # # #  #   #   #####  # #  #   #
 *     #      #    # #  # #   #   #      #  # #   #
 *      ####   ####  #    #   #   ###### #    #   #
 */
.c-content {
  width: 100%; padding-top: 4rem;
  &__wrapper { height: 100%; display: flex; flex-direction: column; }

  &__title {
    width: get-grid-cols(8); margin: auto;
    &-text { margin-bottom: -2.6em; margin-left: 0.5em; }
    &-text span { display: inline-block; width: 0; overflow: hidden; }
    &-logo { width: 100%; }
  }

  &__websites {
    display: grid; grid-template-columns: repeat(4, 1fr); gap: var(--grid-gutter); padding: 2.6rem 0 3rem; margin-top: auto;
    &::before { content: ""; width: 100vw; height: 100%; position: absolute; left: 50%; bottom: 0; background: linear-gradient(180deg, rgb(247 242 243 / 0%) -10.55%, rgb(247 242 243 / 15%) 16.71%, rgb(247 242 243 / 64%) 39.74%, #f7f2f3 100%); transform: translateX(-50%); }
  }
  &__website {
    display: flex; flex-direction: column; align-items: center; text-align: center; background-color: $color-white; border-radius: 1rem; padding: 2.2rem 1.8rem;
    &-logo { max-width: 85%; max-height: 6.5rem; margin: auto 0; }
    &-desc { padding-top: 2rem; margin-top: auto; }
    &-cta { margin-top: 1.4rem; }
  }

  // ================================================================================================================
  // Media Queries
  // ================================================================================================================
  // Tablet and down ------------------------------------------------------------------------------------------------
  @media #{$mq-tablet-and-down} {
    padding-top: 6rem;

    &__title {
      width: 100%;
      &-text { margin-bottom: -3.2em; }
    }
    &__websites {
      grid-template-columns: repeat(2, 1fr); padding: 6rem 0 2.5rem;
      &::before { display: none; }
    }
    &__website {
      &-desc { max-width: 25rem; padding-top: 3rem; }
      &-cta { margin-top: 1.8rem; }
    }
  }

  // Phone only -----------------------------------------------------------------------------------------------------
  @media #{$mq-phone-only} {
    &__title {
      &-text { margin-bottom: -1.4em; }
    }
    &__websites { grid-template-columns: 1fr; grid-auto-rows: 1fr; padding-top: 14rem; }
  }
}
