/* stylelint-disable */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td { margin: 0; padding: 0; border: 0; outline: 0; font-size: 100%; vertical-align: baseline; background: transparent; }
body { line-height: 1; }
ol, ul { list-style: none; }
blockquote, q { quotes: none; }
blockquote:before, blockquote:after, q:before, q:after { content: ''; content: none; }
:focus { outline: 0; }
ins { text-decoration: none; }
del { text-decoration: line-through; }
table { border-collapse: collapse; border-spacing: 0; }
h1, h2, h3, h4, h5, h6 { font-weight: normal; } 
a { text-decoration: none; cursor: pointer; }
html { -webkit-font-smoothing: antialiased; } 
* { position: relative; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box;}
p, h1, h2, h3, h4, h5, h6, strong, a, span, li { -moz-user-select: text; -webkit-user-select: text; -ms-user-select: text; user-select: text; -o-user-select: text; }
input { margin: 0; }
input[type="text"], input[type="email"], input[type="tel"], input[type="password"], input[type="number"], input[type="submit"], select, textarea { -webkit-appearance: none; -moz-appearance: none; border-radius: 0; }
a, a *, input[type="submit"]{ cursor: pointer; }
button, input[type="submit"] { background: transparent; border: none; padding: 0; margin: 0; cursor: pointer; }
button { color: inherit; text-align: left; }
address { font-style: normal; }
figure { margin: 0; }
img { max-width: 100%; height: auto; }
svg { width: 100%; height: auto; }
sup { vertical-align: super; font-size: smaller; }
select::-ms-expand { display: none; }
.gm-style > div { position: initial; } // Pour ne pas cacher les éléments de Google Map
audio, canvas, iframe, img, svg, video { vertical-align: middle; }