@use "sass:math";

/***
*    ######## ##     ## ##    ##  ######  ######## ####  #######  ##    ##  ######
*    ##       ##     ## ###   ## ##    ##    ##     ##  ##     ## ###   ## ##    ##
*    ##       ##     ## ####  ## ##          ##     ##  ##     ## ####  ## ##
*    ######   ##     ## ## ## ## ##          ##     ##  ##     ## ## ## ##  ######
*    ##       ##     ## ##  #### ##          ##     ##  ##     ## ##  ####       ##
*    ##       ##     ## ##   ### ##    ##    ##     ##  ##     ## ##   ### ##    ##
*    ##        #######  ##    ##  ######     ##    ####  #######  ##    ##  ######
*/
@function rem($value) {
  @return calc(#{$value} / #{var(--base-px, $font-base-default)} * 1rem);
}

@function get-grid-cols($cols-count) {
  @if round($cols-count) == $cols-count { // Nombre de colonne en entier (Calcul plus simple)
    @return calc((var(--grid-gutter) * -1) + (var(--grid-gutter) + var(--grid-col-width)) * $cols-count);
  } @else { // Nombre de colonne en décimal
    @return calc(var(--grid-gutter) * (math.ceil($cols-count) - 1) + var(--grid-col-width) * $cols-count);
  }
}
:root {
  --grid-col-width: calc((100vw - (var(--grid-margin) * 2) - (var(--grid-gutter) * (var(--grid-cols) - 1))) / var(--grid-cols));
}


/***
*    ##     ## #### ##     ## #### ##    ##  ######
*    ###   ###  ##   ##   ##   ##  ###   ## ##    ##
*    #### ####  ##    ## ##    ##  ####  ## ##
*    ## ### ##  ##     ###     ##  ## ## ##  ######
*    ##     ##  ##    ## ##    ##  ##  ####       ##
*    ##     ##  ##   ##   ##   ##  ##   ### ##    ##
*    ##     ## #### ##     ## #### ##    ##  ######
*/
@mixin burger-layout ($bar-width, $bar-height, $space-between-bars, $number-of-bars, $bars-color: null) {
  --burger-width: #{$bar-width * 1px};
  --burger-height: #{($bar-height * $number-of-bars) + ($space-between-bars * ($number-of-bars - 1)) * 1px};
  width: var(--burger-width);
  height: var(--burger-height);
  span { position: absolute; width: 100%; left: 0; height: $bar-height * 1px; }
  @for $i from 1 through $number-of-bars {
    span:nth-child(#{$i}) {
      top: (($bar-height * ($i - 1)) + ($space-between-bars * ($i - 1))) * 1px;
      @if $bars-color { background-color: $bars-color; }
    }
  }
}

@mixin placeholder ($color, $font-size) {
  /* stylelint-disable selector-no-vendor-prefix, block-opening-brace-newline-after, block-closing-brace-newline-before, declaration-block-semicolon-newline-after */
  &::-webkit-input-placeholder, /* Chrome/Opera/Safari */
  &::-moz-placeholder, /* Firefox 19+ */
  &:-ms-input-placeholder, /* IE 10+ */
  &:-moz-placeholder, /* Firefox 18- */
  &::placeholder { color: $color; font-size: $font-size; }
  /* stylelint-enable selector-no-vendor-prefix, block-opening-brace-newline-after, block-closing-brace-newline-before, declaration-block-semicolon-newline-after */
}

@mixin text-selection ($bg-color, $txt-color) {
  ::selection { background: $bg-color; color: $txt-color; }
}

@mixin remove-scrollbar {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar { display: none; } /* for Chrome, Safari, and Opera */
}

// ========================================================
// Colonnes
// ========================================================
// Contenu des ff-cols
@mixin ff-cols-content ($parent-class-name: o-cols, $type: "") {
  @for $i from 1 through 12 { // *À ajuster selon le nombre de colonnes du système de grille
    $type-class-add: if($type != "", "-#{$type}", "");
    .#{$parent-class-name} {
      .#{$parent-class-name}__c-#{$i}#{$type-class-add} { width: get-grid-cols($i); max-width: 100%; }
      .#{$parent-class-name}__c-#{$i}-gutter#{$type-class-add} { width: calc(#{get-grid-cols($i)} + var(--grid-gutter)); max-width: 100%; }
    }
  }
}

// Rendu du CSS
@mixin ff-cols-generator ($parent-class-name: o-cols, $type: desktop) {
  @if $type == desktop {
    @include ff-cols-content($parent-class-name);
  } @else {
    @include ff-cols-content($parent-class-name, $type);
  }
}
