/***
 *     ######   #######  ##        #######  ########   ######
 *    ##    ## ##     ## ##       ##     ## ##     ## ##    ##
 *    ##       ##     ## ##       ##     ## ##     ## ##
 *    ##       ##     ## ##       ##     ## ########   ######
 *    ##       ##     ## ##       ##     ## ##   ##         ##
 *    ##    ## ##     ## ##       ##     ## ##    ##  ##    ##
 *     ######   #######  ########  #######  ##     ##  ######
 */
$color-white: #fff;
$color-black: #203037;
$color-off-black: #1e1a17;
$color-blue-dark: #012f70;
$color-accent: #c30f22;
$color-focus: #f8bc49;


/***
 *    ######## ##    ## ########   #######   ######   ########     ###    ########  ##     ## ##    ##
 *       ##     ##  ##  ##     ## ##     ## ##    ##  ##     ##   ## ##   ##     ## ##     ##  ##  ##
 *       ##      ####   ##     ## ##     ## ##        ##     ##  ##   ##  ##     ## ##     ##   ####
 *       ##       ##    ########  ##     ## ##   #### ########  ##     ## ########  #########    ##
 *       ##       ##    ##        ##     ## ##    ##  ##   ##   ######### ##        ##     ##    ##
 *       ##       ##    ##        ##     ## ##    ##  ##    ##  ##     ## ##        ##     ##    ##
 *       ##       ##    ##         #######   ######   ##     ## ##     ## ##        ##     ##    ##
 */
// ================================================================================================================
// Defaults
// ================================================================================================================
$font-base-default: 18;

// ================================================================================================================
// Font Families
// ================================================================================================================
$font-text: "Open Sans", sans-serif;
$font-text-alt: "Staatliches", sans-serif;
$font-title: $font-text;

// ================================================================================================================
// Font Weights
// ================================================================================================================
$fw-thin: 100;
$fw-extralight: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-extrabold: 800;
$fw-black: 900;


/***
 *    ##     ## ######## ########  ####    ###        #######  ##     ## ######## ########  #### ########  ######
 *    ###   ### ##       ##     ##  ##    ## ##      ##     ## ##     ## ##       ##     ##  ##  ##       ##    ##
 *    #### #### ##       ##     ##  ##   ##   ##     ##     ## ##     ## ##       ##     ##  ##  ##       ##
 *    ## ### ## ######   ##     ##  ##  ##     ##    ##     ## ##     ## ######   ########   ##  ######    ######
 *    ##     ## ##       ##     ##  ##  #########    ##  ## ## ##     ## ##       ##   ##    ##  ##             ##
 *    ##     ## ##       ##     ##  ##  ##     ##    ##    ##  ##     ## ##       ##    ##   ##  ##       ##    ##
 *    ##     ## ######## ########  #### ##     ##     ##### ##  #######  ######## ##     ## #### ########  ######
 */
$mq-large-desktop-only: "screen and (min-width: 2100px)";
$mq-desktop-only: "screen and (min-width: 1200px)";
$mq-tablet-and-down: "screen and (max-width: 1199px)";
$mq-tablet-only: "screen and (max-width: 1199px) and (min-width: 750px)";
$mq-landscape-tablet-only: "screen and (max-width: 1199px) and (min-width: 750px) and (orientation: landscape)";
$mq-portrait-tablet-and-down: "screen and (max-width: 1199px) and (orientation: portrait), screen and (max-width: 749px)";
$mq-portrait-tablet-only: "screen and (max-width: 1199px) and (orientation: portrait) and (min-width: 750px)";
$mq-laptop-and-down: "screen and (max-width: 1024px)";
$mq-small-tablet-and-down: "screen and (max-width: 849px)";
$mq-small-tablet-only: "screen and (max-width: 849px) and (min-width: 750px)";
$mq-phone-only: "screen and (max-width: 749px)";
$mq-landscape-phone-only: "screen and (min-width: 500px) and (max-width: 749px) and (orientation: landscape)";
$mq-small-phone-only: "screen and (max-width: 320px)";


/***
 *    ########    ###     ######  #### ##    ##  ######    ######
 *    ##         ## ##   ##    ##  ##  ###   ## ##    ##  ##    ##
 *    ##        ##   ##  ##        ##  ####  ## ##        ##
 *    ######   ##     ##  ######   ##  ## ## ## ##   ####  ######
 *    ##       #########       ##  ##  ##  #### ##    ##        ##
 *    ##       ##     ## ##    ##  ##  ##   ### ##    ##  ##    ##
 *    ######## ##     ##  ######  #### ##    ##  ######    ######
 */
// Out
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart: cubic-bezier(0.25, 1, 0.5, 1);
$ease-out-quint: cubic-bezier(0.22, 1, 0.36, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-circ: cubic-bezier(0, 0.55, 0.45, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);

// In
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart: cubic-bezier(0.5, 0, 0.75, 0);
$ease-in-quint: cubic-bezier(0.64, 0, 0.78, 0);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.55, 0, 1, 0.45);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);

// In-out
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.76, 0, 0.24, 1);
$ease-in-out-quint: cubic-bezier(0.83, 0, 0.17, 1);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ: cubic-bezier(0.85, 0, 0.15, 1);
